import { NavLink } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

import { makeMainPath } from '~/helpers/routes'
import { cn } from '~/utils/cn'

import { Button } from './UI/Button'

export const FooterLinks = ({ className }: { className?: string }) => {
  const { t } = useTranslation()

  return (
    <footer
      id="footer"
      className={cn('flex flex-wrap justify-center gap-4 lg:gap-6', className)}
    >
      <Button
        asChild
        size="sm"
        className="xs:px-auto transform-gpu backdrop-blur-lg"
      >
        <NavLink to={makeMainPath('/help-center')}>
          {t('components.landing_footer_links.help')}
        </NavLink>
      </Button>
      <Button
        asChild
        size="sm"
        className="xs:px-auto transform-gpu backdrop-blur-lg"
      >
        <NavLink to={makeMainPath('/privacy-policy')}>
          {t('components.landing_footer_links.privacy')}
        </NavLink>
      </Button>
      <Button
        asChild
        size="sm"
        className="xs:px-auto transform-gpu backdrop-blur-lg"
      >
        <NavLink to={makeMainPath('/terms-and-conditions')}>
          {t('components.landing_footer_links.terms')}
        </NavLink>
      </Button>
    </footer>
  )
}
